import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as Quagga from 'quagga';
import { map, mergeMap } from 'rxjs/operators';
import { Notification } from 'src/app/shared/models';
import { DeviceLicense } from 'src/app/shared/models/device-license.enum';
import { RuntimeLayoutControl } from 'src/app/shared/models/runtime-layout/control/runtime-layout-control.model';
import { RuntimeLayoutNotifyType } from 'src/app/shared/models/runtime-layout/local-event/runtime-layout-notify.model';
import { RuntimeLayoutDeviceLicense } from 'src/app/shared/models/runtime-layout/runtime-layout-device-license.model';
import { RuntimeLayout } from 'src/app/shared/models/runtime-layout/runtime-layout.model';
import { NotificationService } from 'src/app/shared/services';
import { TranslateService } from 'src/app/shared/services/app';
import { CortexService } from 'src/app/shared/services/app/cortex.service';
import { LogUtils } from 'src/app/shared/utils/log-utils';
import { DEFAULT_CONFIG_QUAGGA } from './barcode-scanner-livestream.config';


@Component({
  selector: 'lc-barcode-scanner-livestream',
  templateUrl: 'barcode-scanner-livestream.component.html',
  styleUrls: ['./barcode-scanner-livestream.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarcodeScannerLivestreamComponent implements OnChanges, OnDestroy {

  readonly decodeForXMs = 250;

  @Input() layout: RuntimeLayout;
  @Input() layoutControl: RuntimeLayoutControl;
  @Input() types: string[];

  @Output() valueChanges = new EventEmitter();

  @ViewChild('barcodeScanner', { static: true }) barcodeScanner;

  private canvas: HTMLCanvasElement;
  private cortexLicenseKey: string;
  private lastResultTick: number;
  private lastFrameTick: number;
  private resultsMap: any;
  started = false;
  private video: HTMLVideoElement;

  constructor(
    private cdr: ChangeDetectorRef,
    private cortexService: CortexService,
    private el: ElementRef,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {

  }

  ngOnDestroy(): void {
    this.stop();
  }

  ngOnChanges(changes: SimpleChanges) {
    // HARDCODED TEMPORARILY
    this.cortexLicenseKey = 'of738PvUZAXr5xYj1RWuqgEPpaknEzTeezeS4dpkWkuf+32tTWVx8WmYPpd6ruHRobXh6h7D8VLa6X1xbMPGgquu+iZeY3PssTwqf1bi/dUEdDIExaLc+PPkmVMOnU9w6ev0lIeXxEjSLZzZ9t8SrmXK8W/0gUK9Mk+GOes1DLUEj64oEsgmDyZJl718N17RCKfkh/DE/ojqA15G+wmBuDstEap5Dbjry0d+pzpHluD8KeYP/Uxqf+bITjjYl/WBDoVPsmQqcy5X5cQxalrbVVkmqWb6HXsHc/48oWJdw47LUKBC6QFV32twFmgWuCvbmLAQe4d0U8bD80ejTFn+f11Xj1rFpQkN64FbP9XYmX+Z47vKFM20/1Nq0NsoWZPp+m+lzO1yY30i/8QljDSvLg==';
    // const cortexLicense = (this.layout?.deviceLicenses || []).find((x: RuntimeLayoutDeviceLicense) => {
    //   return x.deviceLicenseGuidId === DeviceLicense.CortexWebEL1 ||
    //     x.deviceLicenseGuidId === DeviceLicense.CortexWebEL2
    // });
    // this.cortexLicenseKey = cortexLicense?.licenseKey;

    this.restart();
  }

  private _init(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.cortexLicenseKey) {
        this.cortexService.init({
          licenseKey: this.cortexLicenseKey,
        })
        .pipe(
          mergeMap(() => {
            return this.createCortexCanvas();
          }),
          mergeMap(() => {
            return this.cortexService.startCamera();
          })
        )
        .subscribe(() => {
          this.cdr.markForCheck();
          resolve(null);
        }, (error: any) => {
          reject(error);
        });
      } else {
        Quagga.onProcessed((result) => this.onQuaggaProcessed(result));

        Quagga.onDetected((result) => this.onQuaggaDetected(result));

        DEFAULT_CONFIG_QUAGGA.inputStream.target = this.barcodeScanner.nativeElement;

        if (this.types && this.types.length) {
          DEFAULT_CONFIG_QUAGGA.decoder.readers = this.types.map((type: string) => {
            return type + '_reader';
          });
        }

        Quagga.init(DEFAULT_CONFIG_QUAGGA, (error) => {
          if (error) return reject(error);

          resolve(null);
        });
      }
    });
  }

  start() {
    if (this.started) return;

    this._init()
    .then(() => {
      if (this.cortexLicenseKey) {
        this.startCortexVideoCapturing();
      } else {
        Quagga.start();
      }

      this.started = true;
      this.cdr.markForCheck();
    }).catch((error: any) => {
      if (error?.name === 'NotFoundError') {
        LogUtils.error(error);
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: error.message,
          type: RuntimeLayoutNotifyType.Unknown,
        }));
        return;
      }

      throw error;
    });
  }

  private createCortexCanvas() {
    return this.cortexService.getResolutionDimensions()
    .pipe(
      map((resolution: { width: number, height: number}) => {
        this.video = document.createElement('video');
        this.video.setAttribute('id','video');
        this.video.setAttribute('playsinline', 'true');
        this.video.setAttribute('width', resolution.width.toString());
        this.video.setAttribute('height', resolution.height.toString());
        this.barcodeScanner.nativeElement.appendChild(this.video);

        // Canvas is used for drawing rectangles over the image
        this.canvas = document.createElement('canvas');
        this.canvas.setAttribute('width', resolution.width.toString());
        this.canvas.setAttribute('height', resolution.height.toString());
        this.canvas.setAttribute('style','position: absolute; top: 0; left: 0;');
        this.canvas.onclick = (e) => {
          const canvasContext = this.canvas.getContext('2d');

          const scaleX = this.canvas.clientWidth / this.canvas.width;
          const scaleY = this.canvas.clientHeight / this.canvas.height;
          // correct mouse coordinates:
          const rect = this.canvas.getBoundingClientRect();  // make x/y relative to canvas
          const x = (e.clientX - rect.left) / scaleX;
          const y = (e.clientY - rect.top) / scaleY;

          // check which barcode:
          for (const r of Object.values(this.resultsMap || {}) as any) {
            const path = r.box;
            const def = { x: 0, y: 1, };
            canvasContext.strokeStyle = 'red';
            canvasContext.fillStyle = 'red';
            canvasContext.lineWidth = 2;

            canvasContext.beginPath();  // we build a path to check with, but not to draw
            canvasContext.moveTo(path[0][def.x], path[0][def.y]);
            for (var j = 1; j < path.length; j++) {
              canvasContext.lineTo(path[j][def.x], path[j][def.y]);
            }
            canvasContext.closePath();

            if (canvasContext.isPointInPath(x, y)) {
              canvasContext.stroke();
              console.warn('Barcode Clicked: ' + r.barcodeData);
              this.valueChanges.next(r);
              break;
            }
          }
        };
        this.barcodeScanner.nativeElement.appendChild(this.canvas);

        this.cdr.markForCheck();
      })
    );
  }

  private startCortexVideoCapturing() {
    this.lastResultTick = undefined;
    this.resultsMap = {} as any;

    this.cortexService.startVideoCapture((results: any[]) => {
      if (this.video.paused) return;

      const canvasContext = this.canvas.getContext('2d');

      const now = Date.now();
      if (now - this.lastFrameTick >= 20) {
        canvasContext.clearRect(0, 0, parseInt(this.canvas.getAttribute('width')), parseInt(this.canvas.getAttribute('height')));
      }
      this.lastFrameTick = now;

      const keys = Object.keys(this.resultsMap);
      if (this.layoutControl?.parseRV('InputBuffering') && this.layoutControl?.parseRV('InputBufferingItemCount')) {
        if (keys.length === this.layoutControl?.parseRV('InputBufferingItemCount')) {
          this.valueChanges.next(Object.values(this.resultsMap));
        }
      } else if (this.lastResultTick && now - this.lastResultTick >= this.decodeForXMs) {
        if (this.layoutControl?.parseRV('InputBuffering')) this.valueChanges.next(Object.values(this.resultsMap));
        else if (keys.length === 1) this.valueChanges.next(this.resultsMap[keys[0]]);
        else this.video.pause();
      }

      for (const r of results || []) {
        if (!r?.barcodeData) continue;

        r.barcodeData = r.barcodeData.replace(/(\r\n|\n|\r)/gm, '');
        if (!this.resultsMap[r.barcodeData]) this.lastResultTick = now;

        this.resultsMap[r.barcodeData] = r;
      }

      this.highlightBarcodes(canvasContext);
    }).subscribe();
  }

  private highlightBarcodes(canvasContext: CanvasRenderingContext2D) {
    for (const r of Object.values(this.resultsMap) as any) {
      r.box = [
        [r.barcodeCoordinates.BottomLeft.X, r.barcodeCoordinates.BottomLeft.Y],
        [r.barcodeCoordinates.TopLeft.X, r.barcodeCoordinates.TopLeft.Y],
        [r.barcodeCoordinates.TopRight.X, r.barcodeCoordinates.TopRight.Y],
        [r.barcodeCoordinates.BottomRight.X, r.barcodeCoordinates.BottomRight.Y],
      ];

      this.drawPath(
        r.box,
        { x: 0, y: 1, },
        canvasContext,
        {
          color: 'green',
          lineWidth: 2,
        }
      );
    }
  }

  private drawPath(path: number[], def: any, ctx: CanvasRenderingContext2D, style: any) {
    ctx.strokeStyle = style.color;
    ctx.fillStyle = style.color;
    ctx.lineWidth = style.lineWidth;

    ctx.beginPath();
    ctx.moveTo(path[0][def.x], path[0][def.y]);
    for (var j = 1; j < path.length; j++) {
        ctx.lineTo(path[j][def.x], path[j][def.y]);
    }
    ctx.closePath();
    ctx.stroke();
  }

  stop() {
    if (this.started) {
      if (this.cortexLicenseKey) {
        this.cortexService.stopVideoCapture()
        .subscribe(() => {
          (this.el.nativeElement.children[0] as HTMLElement).innerHTML = '';
        });
      } else {
        Quagga.stop();
      }

      this.started = false;
      this.cdr.markForCheck();
    }
  }

  restart() {
    if (this.started) {
      this.stop();
      this.start();
    }
  }

  private onQuaggaProcessed(result: any): any {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width')), parseInt(drawingCanvas.getAttribute('height')));
        result.boxes.filter(function (box) {
          return box !== result.box;
        }).forEach(function (box) {
          Quagga.ImageDebug.drawPath(box,
            { x: 0, y: 1, },
            drawingCtx,
            {
              color: 'green',
              lineWidth: 2,
            }
          );
        });
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(
          result.box,
          { x: 0, y: 1, },
          drawingCtx,
          {
            color: '#00F',
            lineWidth: 2,
          }
        );
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(
          result.line,
          { x: 'x', y: 'y', },
          drawingCtx,
          {
            color: 'red',
            lineWidth: 3,
          }
        );
      }

    }
  }

  private onQuaggaDetected(result) {
    const code = result.codeResult.code;
    let countDecodedCodes = 0;
    let err = 0;
    for (const decodedCode of result.codeResult.decodedCodes) {
      if (decodedCode.error != undefined) {
        countDecodedCodes++;
        err += parseFloat(decodedCode.error);
      }
    }

    if (err / countDecodedCodes < 0.08 && this.sanityCheck(code)) {
      this.valueChanges.next(result.codeResult);
    } else {
      console.warn('barcode false positive: ' + code)
    }
  }

  private sanityCheck(s) {
    return s.toUpperCase().match(/^[0-9A-Za-z\s\-\|\/:]+$/);
  }

}

