import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { ControlCamera1Module } from './camera1/control-camera1.module';
import { ControlChecklist1Module } from './checklist1/control-checklist1.module';
import { ControlExternal1Module } from './external1/control-external1.module';
import { ControlInput1Module } from './input1/control-input1.module';
import { ControlList1Module } from './list1/control-list1.module';
import { ControlLocation1Module } from './location1/control-location1.module';
import { ControlMainMenu1Component } from './mainmenu1/control-mainmenu1.component';
import { ControlMenu1Component } from './menu1/control-menu1.component';
import { ControlPrint1Module } from './print1/control-print1.module';
import { ControlQuantityList1Module } from './quantity-list1/control-quantity-list1.module';
import { ControlScale1Module } from './scale1/control-scale1.module';
import { ControlSmartImage1Component } from './smartimage1/control-smartimage1.component';
import { ControlSog1Module } from './sog1/control-sog1.module';
import { ControlSwishQr1Module } from './swish-qr1/control-swish-qr1.module';
import { ControlTemp1Module } from './temp1/control-temp1.module';
import { ControlUpdateESL1Module } from './update-esl1/control-update-esl1.module';
import { ControlUpdate1Module } from './update1/control-update1.module';
import { ControlVisual1Module } from './visual1/control-visual1.module';
import { ControlRfidInventoryModule } from './rfid-inventory/control-rfid-inventory.module';
import { ControlRfidLocateComponent } from './rfid-locate/control-rfid-locate.component';
import { ControlRfidScanComponent } from './rfid-scan/control-rfid-scan.component';


@NgModule({
  declarations: [
    ControlMainMenu1Component,
    ControlMenu1Component,
    ControlSmartImage1Component,
  ],
  exports: [
    ControlMainMenu1Component,
    ControlMenu1Component,
    ControlSmartImage1Component,

    ControlCamera1Module,
    ControlChecklist1Module,
    ControlExternal1Module,
    ControlInput1Module,
    ControlList1Module,
    ControlLocation1Module,
    ControlPrint1Module,
    ControlQuantityList1Module,
    ControlRfidInventoryModule,
    ControlRfidLocateComponent,
    ControlRfidScanComponent,
    ControlScale1Module,
    ControlSog1Module,
    ControlSwishQr1Module,
    ControlTemp1Module,
    ControlUpdate1Module,
    ControlUpdateESL1Module,
    ControlVisual1Module,
    SharedPipesModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,

    ControlCamera1Module,
    ControlChecklist1Module,
    ControlExternal1Module,
    ControlInput1Module,
    ControlList1Module,
    ControlLocation1Module,
    ControlPrint1Module,
    ControlQuantityList1Module,
    ControlRfidInventoryModule,
    ControlRfidLocateComponent,
    ControlRfidScanComponent,
    ControlSog1Module,
    ControlSwishQr1Module,
    ControlUpdate1Module,
    ControlUpdateESL1Module,
    ControlVisual1Module,
    SharedPipesModule,
  ],
})
export class ControlsModule { }
